import constants from 'constants/index'

const statusColor = (statusId, classes) => {
  if (
    constants.dataProcess.LIA_STATUSES.REVIEW_LIA_STATUSES.includes(statusId)
  ) {
    return classes.reviewLia
  }

  switch (statusId) {
    case constants.dataProcess.PENDING_STATUS:
      return classes.pending
    case constants.dataProcess.WAITING_REVIEW_STATUS:
      return classes.waitingReview
    case constants.dataProcess.DISAPPROVED_STATUS:
      return classes.reviewDisapproved
    case constants.dataProcess.APPROVED_STATUS:
      return classes.reviewApproved
    case constants.dataProcess.REVIEWING_STATUS:
      return classes.reviewing
    case constants.dataProcess.INACTIVE_STATUS:
      return classes.inactive
    case constants.dataProcess.AUTO_REVIEW_STATUS:
      return classes.autoReview
    case constants.dataProcess.LIA_STATUSES.PENDING_LIA_STATUS:
      return classes.pendingLia
    case constants.dataProcess.LIA_STATUSES.DISAPPROVED_LIA_STATUS:
      return classes.disapprovedLia
    default:
      return ''
  }
}

export default statusColor
