import PropTypes from 'prop-types'

import helpers from 'helpers'

import { Box, Tooltip, Typography } from '@material-ui/core'

const CardColumn = ({ icon, title, subtitle, width, ...rest }) => (
  <Box
    width={width}
    display="flex"
    alignItems="center"
    p={1}
    marginLeft={rest.marginLeft}
  >
    <Box px={1} py={2}>
      {icon}
    </Box>
    <Box maxWidth="87%">
      <Typography variant="subtitle2" align="left">
        {title}
      </Typography>
      <Box>
        <Tooltip title={subtitle} placement="bottom-start">
          <Typography color="secondary" align="left" {...rest}>
            {helpers.functions.capitalizeText(subtitle)}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  </Box>
)

CardColumn.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  width: PropTypes.string,
}

CardColumn.defaultProps = {
  width: '50%',
}

export default CardColumn
