import { useState } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Chip, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { Edit as EditIcon, EyeOff as InactiveIcon } from 'react-feather'

import { MenuButton } from 'components'
import { TableRow, TableCell } from 'components/Table'
import { IntegrationPartnerInactiveOrActiveDialog } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import useStyles from './styles'

const IntegrationPartnerTableRow = ({ integrationPartner, refresh }) => {
  const classes = useStyles()
  const history = useHistory()
  const isActive = integrationPartner.status === 'active'
  const planPrice = integrationPartner?.plan?.price

  const [openModalActiveOrInactive, setOpenModalActiveOrInactive] =
    useState(false)

  const navigateToEdit = (integrationPartnerId) => {
    history.push(
      reverse(routes.integrationPartner.edit, {
        integrationPartnerId: integrationPartnerId,
      }),
    )
  }

  const ModalActiveOrInactive = () => {
    setOpenModalActiveOrInactive(true)
  }

  return (
    <>
      <TableRow key={integrationPartner?.id}>
        <TableCell>{integrationPartner?.id}</TableCell>
        <TableCell>{integrationPartner?.name}</TableCell>
        <TableCell>{integrationPartner?.plan?.name ?? '-'}</TableCell>
        <TableCell>{planPrice ? `R$ ${planPrice}` : '-'}</TableCell>
        <TableCell disableTooltip>
          {
            constants.integrationPartner.PLATFORM_LABELS[
              integrationPartner?.platform
            ]
          }
        </TableCell>
        <TableCell align="center">
          <Chip
            size="medium"
            className={clsx({
              [classes.chip]: isActive,
              [classes.inactive]: !isActive,
            })}
            label={
              constants.integrationPartner.STATUSES_LABEL[
                integrationPartner?.status
              ]
            }
          />
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(integrationPartner?.updatedAt)}
        </TableCell>
        <TableCell disableTooltip align="center">
          <MenuButton>
            <Button
              startIcon={<EditIcon className={classes.icons} />}
              onClick={() => {
                navigateToEdit(integrationPartner?.id)
              }}
            >
              EDITAR
            </Button>
            <Button
              startIcon={isActive && <InactiveIcon className={classes.icons} />}
              onClick={ModalActiveOrInactive}
            >
              {isActive ? 'INATIVAR' : 'ATIVAR'}
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
      <IntegrationPartnerInactiveOrActiveDialog
        open={openModalActiveOrInactive}
        setOpen={setOpenModalActiveOrInactive}
        isActive={isActive}
        integrationPartner={integrationPartner}
        refresh={refresh}
      />
    </>
  )
}

IntegrationPartnerTableRow.propTypes = {
  integrationPartner: PropTypes.object,
  refresh: PropTypes.func,
}

IntegrationPartnerTableRow.defaultProps = {
  refresh: () => {},
}

export default IntegrationPartnerTableRow
