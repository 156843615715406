import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    marginTop: '13px',
    marginLeft: '11px',
    marginRight: '11px',
  },
  textPending: {
    color: theme.palette.azure,
    fontWeight: 700,
  },
  textWaitingReview: {
    color: theme.palette.yellowOrange,
    fontWeight: 700,
  },
  textReviewDisapproved: {
    color: theme.palette.cgRed,
    fontWeight: 700,
  },
  textReviewApproved: {
    color: theme.palette.greenPantone,
    fontWeight: 700,
  },
  textReviewing: {
    color: theme.palette.yellowOrange,
    fontWeight: 700,
  },
  textInactive: {
    color: theme.palette.white,
    fontWeight: 700,
  },
  textSuggested: {
    color: theme.palette.darkSkyBlue,
    fontWeight: 700,
  },
  textDiscarded: {
    color: theme.palette.davysGray,
    fontWeight: 700,
  },
  inactive: {
    backgroundColor: colors.grey[500],
  },
  pending: {
    backgroundColor: theme.palette.darkBlue,
  },
  waitingReview: {
    backgroundColor: theme.palette.antiqueWhite,
  },
  reviewDisapproved: {
    backgroundColor: theme.palette.lavenderBlush,
  },
  reviewApproved: {
    backgroundColor: theme.palette.honeydew,
  },
  reviewing: {
    backgroundColor: theme.palette.antiqueWhite,
  },
  suggested: {
    backgroundColor: theme.palette.primaryMain,
  },
  discarded: {
    backgroundColor: theme.palette.gainsboro,
  },
  pendingLia: {
    backgroundColor: theme.palette.lightBlue,
  },
  reviewLia: {
    backgroundColor: theme.palette.softYellow,
  },
  disapprovedLia: {
    backgroundColor: theme.palette.lightCoral,
  },
  lowFragility: {
    backgroundColor: theme.palette.darkBlue,
  },
  mediumFragility: {
    backgroundColor: theme.palette.honeydew,
  },
  highFragility: {
    backgroundColor: theme.palette.antiqueWhite,
  },
  severeFragility: {
    backgroundColor: theme.palette.lavenderBlush,
  },
  textLowFragility: {
    color: theme.palette.azure,
    fontWeight: 700,
  },
  textMediumFragility: {
    color: theme.palette.greenPantone,
    fontWeight: 700,
  },
  textHighFragility: {
    color: theme.palette.sandyBrown,
    fontWeight: 700,
  },
  textSevereFragility: {
    color: theme.palette.cgRed,
    fontWeight: 700,
  },
  dataProcessNameBox: {
    display: 'flex',
  },
  blueDot: {
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    marginRight: '3px',
  },
}))

export default styles
