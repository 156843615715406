import constants from 'constants/index'

const statusLabel = (statusId) => {
  if (
    constants.dataProcess.LIA_STATUSES.REVIEW_LIA_STATUSES.includes(statusId)
  ) {
    return 'Revisão lia'
  }

  switch (statusId) {
    case constants.dataProcess.PENDING_STATUS:
      return 'Pendente'
    case constants.dataProcess.WAITING_REVIEW_STATUS:
      return 'Aguardando revisão'
    case constants.dataProcess.DISAPPROVED_STATUS:
      return 'Reprovado'
    case constants.dataProcess.APPROVED_STATUS:
      return 'Aprovado'
    case constants.dataProcess.REVIEWING_STATUS:
      return 'Em revisão'
    case constants.dataProcess.INACTIVE_STATUS:
      return 'Inativo'
    case constants.dataProcess.AUTO_REVIEW_STATUS:
      return 'Revisão automática'
    case constants.dataProcess.LIA_STATUSES.PENDING_LIA_STATUS:
      return 'Pendente lia'
    case constants.dataProcess.LIA_STATUSES.DISAPPROVED_LIA_STATUS:
      return 'Reprovado lia'
    default:
      return ''
  }
}

export default statusLabel
