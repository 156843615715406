import { useState } from 'react'

import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'

import { LoadingButton } from 'components'

import * as service from 'service'

import useStyles from './styles'

const IntegrationPartnerTokenDestroy = ({
  integrationPartnerId,
  integrationPartnerToken,
  open,
  setOpen,
  refresh,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const handleDestroy = async () => {
    setLoading(true)
    try {
      await service.dponet.integrationPartners.destroyToken(
        integrationPartnerId,
        integrationPartnerToken?.id,
      )
      refresh()
      snackbar.open({
        message: `Token de integração do parceiro excluído com sucesso`,
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Falha ao excluir token de integração do parceiro`,
        variant: 'error',
      })
    }
    setOpen(false)
    setLoading(false)
    refresh()
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="last-token-destroy"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.textTitle}>
          TEM CERTEZA QUE DESEJA EXCLUIR ESSE TOKEN DE INTEGRAÇÃO?
        </Typography>
      </DialogTitle>
      <Divider />
      <Box>
        <DialogContent>
          <Box pb={1}>
            <Typography>
              Se removido, o parceiro não poderá realizar integrações com esse
              token.
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text="EXCLUIR"
          loading={loading}
          onClick={handleDestroy}
        />
      </DialogActions>
    </Dialog>
  )
}

IntegrationPartnerTokenDestroy.defaultProps = {
  integrationPartnerToken: {},
  setOpen: () => {},
  refresh: () => {},
}

export default IntegrationPartnerTokenDestroy
