import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#233154'
const primaryMain = '#233154'
const primaryLight = '#F3FAFF'
const primaryHover = '#0763A0'
const secondaryDark = '#233154'
const secondaryMain = '#657786'
const secondaryLight = '#AAB8C2'
const secondarySuperLight = '#E1E8ED'
const backgroundDefault = '#F4F6F8'

const darkBlue = '#087DF138'
const linkHelp = '#3024AE'
const azure = '#087DF1'
const teal = '#148585'
const mayaBlue = '#65C8FF'
const darkSkyBlue = '#74BFE1'
const blueViolet = '#671684'
const darkRed = '#B71C1C'
const cgRed = '#E53935'
const amaranth = '#E53555'
const lightPink = '#FFADAD'
const yellowOrange = '#FF9800'
const sandyBrown = '#F4A460'
const navajoWhite = '#FFDEAD'
const greenPantone = '#4CAF50'
const grannySmithApple = '#AFFFBC'
const davysGray = '#535151'
const spanishGray = '#999999'
const lightGray = '#CCCCCC'
const gainsboro = '#DFDFDF'
const honeydew = '#E8F5E9'
const cultured = '#F1F1F1'
const antiqueWhite = '#FFF3E0'
const lavenderBlush = '#FEEBEE'
const veryDarkGrayishBlue = '#343A40'
const spanishBlue = '#186CBF'
const rawSienna = '#DD814D'
const crimsonRed = '#861313'
const forestGreenWeb = '#208824'
const battleshipGrey = '#7D817D'
const russianViolet = '#420E54'
const deepJungleGreen = '#0E5C5C'
const backgroundIconGray = '#EDEFF0'
const iconGray = '#657687'
const titleTableCell = '#263238'
const subtitle2Color = '#546E7A'
const backgroundInputColor = '#F3FAFF'
const BlueDownDark = '#233154'
const brightBlue = '#3C99F4'
const orangeSoft = '#FFB111'
const iceGray = '#F4F6F8'
const lightBlue = '#66b3ff'
const softYellow = '#ffe08a'
const lightCoral = '#f28b8b'

const palette = {
  amaranth,
  antiqueWhite,
  azure,
  backgroundIconGray,
  battleshipGrey,
  black,
  BlueDownDark,
  blueViolet,
  brightBlue,
  cgRed,
  crimsonRed,
  cultured,
  darkBlue,
  darkRed,
  darkSkyBlue,
  davysGray,
  deepJungleGreen,
  forestGreenWeb,
  gainsboro,
  grannySmithApple,
  greenPantone,
  honeydew,
  iceGray,
  iconGray,
  lavenderBlush,
  lightBlue,
  lightCoral,
  lightGray,
  lightPink,
  mayaBlue,
  navajoWhite,
  orangeSoft,
  rawSienna,
  russianViolet,
  sandyBrown,
  softYellow,
  spanishBlue,
  spanishGray,
  teal,
  veryDarkGrayishBlue,
  white,
  yellowOrange,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: primaryMain,
    light: primaryLight,
    hover: primaryHover,
  },
  secondary: {
    contrastText: white,
    dark: secondaryDark,
    main: secondaryMain,
    light: secondaryLight,
    superLight: secondarySuperLight,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: secondaryDark,
    menu: secondaryMain,
    secondary: secondaryMain,
    link: colors.blue[600],
    linkHelp: linkHelp,
    tableTitle: titleTableCell,
    subtitle2: subtitle2Color,
    backgroundInputColor: backgroundInputColor,
  },
  background: {
    default: backgroundDefault,
    paper: white,
  },
  statuses: {
    pending: azure,
    disapproved: amaranth,
    approved: greenPantone,
    inactive: yellowOrange,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300],
}

export default palette
