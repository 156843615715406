import { useState } from 'react'

import { Button, Chip } from '@material-ui/core'
import clsx from 'clsx'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Card, MenuButton } from 'components'
import { DestroyDialog } from './components'

import { useSnackbar } from 'hooks'

import { Plus as PlusIcon } from 'react-feather'

import useStyles from './styles'

import constants from 'constants/index'
import formatters from 'helpers/formatters'

const IntegrationTokensTable = ({
  integrationPartnerId,
  integrationPartnerTokens,
  handleCreateToken,
  refresh,
}) => {
  const [integrationPartnerTokenState, setIntegrationPartnerToken] = useState()
  const [openDestroy, setOpenDestroy] = useState(false)
  const classes = useStyles()
  const snackbar = useSnackbar()

  const copyToken = (token) => {
    window.navigator.clipboard.writeText(token)
    snackbar.open({
      message: 'Token copiado com sucesso!',
      variant: 'success',
    })
  }

  const handleDestroy = (integrationPartnerToken) => {
    setIntegrationPartnerToken(integrationPartnerToken)
    setOpenDestroy(true)
  }

  return (
    <Card
      title="Tokens de integração"
      actionButton={
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon />}
          onClick={handleCreateToken}
        >
          Criar
        </Button>
      }
      dropdown
      defaultExpanded
    >
      <Table emptyMessage="Nenhum token de integração foi encontrado">
        <TableHead>
          <TableRow>
            <TableCell width="15%">ID</TableCell>
            <TableCell width="55%">Token</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="10%">Data de criação</TableCell>
            <TableCell width="10%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationPartnerTokens?.map((integrationPartnerToken) => (
            <TableRow key={integrationPartnerToken?.id} hover>
              <TableCell disableTooltip>
                {integrationPartnerToken?.id}
              </TableCell>
              <TableCell
                onClick={() => copyToken(integrationPartnerToken?.token)}
                noOverFlow
                disableTooltip
              >
                {integrationPartnerToken?.token}
              </TableCell>
              <TableCell disableTooltip>
                <Chip
                  className={clsx(classes.root, {
                    [classes.active]:
                      integrationPartnerToken?.status ===
                      constants.integrationPartner.STATUSES.active,
                    [classes.inactive]:
                      integrationPartnerToken?.status ===
                      constants.integrationPartner.STATUSES.inactive,
                  })}
                  align="center"
                  size="small"
                  label={
                    integrationPartnerToken?.status ===
                    constants.integrationPartner.STATUSES.active
                      ? 'Ativo'
                      : 'Inativo'
                  }
                />
              </TableCell>
              <TableCell disableTooltip>
                {formatters.date(integrationPartnerToken?.createdAt)}
              </TableCell>
              <TableCell align="right" disableTooltip>
                <MenuButton>
                  <Button
                    size="small"
                    onClick={() => copyToken(integrationPartnerToken?.token)}
                  >
                    Copiar
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleDestroy(integrationPartnerToken)}
                  >
                    Excluir
                  </Button>
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DestroyDialog
        integrationPartnerId={integrationPartnerId}
        integrationPartnerToken={integrationPartnerTokenState}
        open={openDestroy}
        setOpen={setOpenDestroy}
        refresh={refresh}
      />
    </Card>
  )
}

export default IntegrationTokensTable
