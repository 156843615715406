const styles = (theme) => ({
  card: {
    minHeight: '100%',
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      borderRadius: 4,
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardHeader: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  cardHeaderContent: {
    maxWidth: '90%',
  },
  cardContent: {
    padding: '0px !important',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardLinkContent: {
    padding: '0px !important',
    cursor: 'pointer',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  statusSection: {
    textAlign: 'center',
  },
  textWhite: {
    color: theme.palette.white,
  },
  nameEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '18rem',
  },
  departmentEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '11rem',
  },
  pending: {
    backgroundColor: theme.palette.azure,
  },
  waitingReview: {
    backgroundColor: theme.palette.yellowOrange,
  },
  reviewDisapproved: {
    backgroundColor: theme.palette.darkRed,
  },
  reviewApproved: {
    backgroundColor: theme.palette.greenPantone,
  },
  reviewing: {
    backgroundColor: theme.palette.blueViolet,
  },
  inactive: {
    backgroundColor: theme.palette.spanishGray,
  },
  autoReview: {
    backgroundColor: theme.palette.teal,
  },
  suggested: {
    backgroundColor: theme.palette.primaryMain,
  },
  discarded: {
    backgroundColor: theme.palette.gainsboro,
  },
  pendingLia: {
    backgroundColor: theme.palette.lightBlue,
  },
  reviewLia: {
    backgroundColor: theme.palette.softYellow,
  },
  disapprovedLia: {
    backgroundColor: theme.palette.lightCoral,
  },
  textPending: {
    color: theme.palette.mayaBlue,
    fontWeight: 700,
  },
  textWaitingReview: {
    color: theme.palette.navajoWhite,
    fontWeight: 700,
  },
  textReviewDisapproved: {
    color: theme.palette.lightPink,
    fontWeight: 700,
  },
  textReviewApproved: {
    color: theme.palette.grannySmithApple,
    fontWeight: 700,
  },
  textReviewing: {
    color: theme.palette.navajoWhite,
    fontWeight: 700,
  },
  textInactive: {
    color: theme.palette.lightGray,
    fontWeight: 700,
  },
  textSuggested: {
    color: theme.palette.darkSkyBlue,
    fontWeight: 700,
  },
  textDiscarded: {
    color: theme.palette.davysGray,
    fontWeight: 700,
  },
  justifyFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  justifyFlexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  fontBold: {
    fontWeight: 800,
  },
  lowFragility: {
    color: theme.palette.azure,
    fontWeight: 700,
  },
  mediumFragility: {
    color: theme.palette.greenPantone,
    fontWeight: 700,
  },
  highFragility: {
    color: theme.palette.sandyBrown,
    fontWeight: 700,
  },
  severeFragility: {
    color: theme.palette.cgRed,
    fontWeight: 700,
  },
  blueDot: {
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    marginLeft: '4px',
  },
})

export default styles
