import * as yup from 'yup'

import { cookiePlanConfigSchema } from '../CookiePlanSelect'

import constants from 'constants/index'

const schemaIntegrationPartner = yup.object().shape({
  name: yup.string().required(),
  platform: yup.string().required(),
  sendEmails: yup.boolean(),
  createBanner: yup.boolean(),
  planId: yup.string().when('platform', {
    is: constants.integrationPartner.PLATFORMS.easy,
    then: (schema) => schema.required(),
  }),
  cookiePlan: yup.string().when('platform', {
    is: constants.integrationPartner.PLATFORMS.easy,
    then: (schema) => schema.required(),
  }),
  cookiePlanConfig: yup.object().when('cookiePlan', {
    is: constants.integrationPartner.COOKIE_PLANS.custom,
    then: (schema) => schema.concat(cookiePlanConfigSchema),
  }),
})

export default schemaIntegrationPartner
