import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import theme from 'theme'

import constants from 'constants/index'

import {
  makeStyles,
  Card,
  Typography,
  Box,
  Grid,
  Tooltip,
  Button,
} from '@material-ui/core'

import {
  Loader as LoaderIcon,
  Eye as EyeIcon,
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
  Layers as LayersIcon,
} from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const DataProcessStatusCardComponent = ({ filter, card, handleClick }) => {
  const classes = useStyles()

  return (
    <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
      <Card
        className={clsx(classes.root, {
          [classes.selectedCard]: filter?.filters?.statusId === card.statusId,
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          p={1}
          px={2}
          id="dataProcessStatusCard"
          onClick={() => handleClick(card.statusId)}
        >
          <Box pr={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.statusCard}
              style={{ backgroundColor: card.color }}
            >
              {card.icon}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              className={classes.titleBox}
            >
              <Tooltip title={card.label} placement="bottom-start">
                <Typography
                  color="textSecondary"
                  variant="h6"
                  className={classes.titleCard}
                  noWrap
                >
                  {card.label}
                </Typography>
              </Tooltip>
              <Typography
                color="textPrimary"
                variant="body1"
                className={classes.valueCard}
              >
                {card.value}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}

const dataProcessColors = theme.colors.dataProcess.backgroundStatuses
const DataProcessStatusCards = ({ filter, processesStats }) => {
  const [showAll, setShowAll] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const classes = useStyles()

  const handleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleClick = (statusId) => {
    if (statusId !== filter.filters.statusId) {
      filter.setFilters({ ...filter.filters, statusId })
    }
  }

  const waitingReviewCount = (data) => {
    const waitingReviewCountValue = data?.waitingReview - data?.reviewing
    if (waitingReviewCountValue >= 0) {
      return waitingReviewCountValue
    }
    return '-'
  }

  const cards = [
    {
      color: dataProcessColors.total,
      icon: <LayersIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.TOTAL_LABEL,
      value: processesStats?.total ?? '-',
      statusId: null,
    },
    {
      color: dataProcessColors.pending,
      icon: <LoaderIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.PENDING_LABEL,
      value: processesStats?.pending ?? '-',
      statusId: constants.dataProcess.PENDING_STATUS,
    },
    {
      color: dataProcessColors.waitingReview,
      icon: <EyeIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.WAITING_REVIEW_LABEL,
      value: waitingReviewCount(processesStats) ?? '-',
      statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
    },
    {
      color: dataProcessColors.reproved,
      icon: <AlertCircleIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.DISAPPROVED_LABEL,
      value: processesStats?.disapproved ?? '-',
      statusId: constants.dataProcess.DISAPPROVED_STATUS,
    },
    {
      color: dataProcessColors.approved,
      icon: <CheckIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.APPROVED_LABEL,
      value: processesStats?.approved ?? '-',
      statusId: constants.dataProcess.APPROVED_STATUS,
    },
    {
      color: dataProcessColors.inactive,
      icon: <MoonIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.INACTIVE_LABEL,
      value: processesStats?.inactive ?? '-',
      statusId: constants.dataProcess.INACTIVE_STATUS,
    },
    {
      color: dataProcessColors.reviewing,
      icon: <EyeIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.REVIEWING_LABEL,
      value: processesStats?.reviewing ?? '-',
      statusId: constants.dataProcess.REVIEWING_STATUS,
    },
    {
      color: dataProcessColors.autoReview,
      icon: <EyeIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.AUTO_REVIEW_LABEL,
      value: processesStats?.autoReview ?? '-',
      statusId: constants.dataProcess.AUTO_REVIEW_STATUS,
    },
    {
      color: dataProcessColors.pendingLia,
      icon: <LoaderIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.PENDING_LIA_LABEL,
      value: processesStats?.pendingLia ?? '-',
      statusId: constants.dataProcess.LIA_STATUSES.PENDING_LIA_STATUS,
    },
    {
      color: dataProcessColors.reviewLia,
      icon: <EyeIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.REVIEW_LIA_LABEL,
      value: processesStats?.reviewLia ?? '-',
      statusId: constants.dataProcess.LIA_STATUSES.REVIEW_LIA_STATUSES,
    },
    {
      color: dataProcessColors.disapprovedLia,
      icon: <AlertCircleIcon color={theme.palette.white} size="18px" />,
      label: constants.dataProcess.DISAPPROVED_LIA_LABEL,
      value: processesStats?.disapprovedLia ?? '-',
      statusId: constants.dataProcess.LIA_STATUSES.DISAPPROVED_LIA_STATUS,
    },
  ]

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWindowWidth(newWidth)
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <Box display="flex" width="100%">
      <Grid container spacing={3}>
        {cards.map((card, index) => {
          if (windowWidth >= 1920 && !showAll && index <= 3) {
            return (
              <DataProcessStatusCardComponent
                key={index}
                filter={filter}
                card={card}
                handleClick={handleClick}
              />
            )
          } else if (
            windowWidth < 1920 &&
            windowWidth >= 960 &&
            !showAll &&
            index <= 2
          ) {
            return (
              <DataProcessStatusCardComponent
                key={index}
                filter={filter}
                card={card}
                handleClick={handleClick}
              />
            )
          } else if (
            windowWidth < 960 &&
            windowWidth >= 600 &&
            !showAll &&
            index <= 1
          ) {
            return (
              <DataProcessStatusCardComponent
                key={index}
                index={index}
                filter={filter}
                card={card}
                handleClick={handleClick}
              />
            )
          } else if (windowWidth < 600 && !showAll && index === 0) {
            return (
              <DataProcessStatusCardComponent
                key={index}
                index={index}
                filter={filter}
                card={card}
                handleClick={handleClick}
              />
            )
          } else if (showAll) {
            return (
              <DataProcessStatusCardComponent
                key={index}
                index={index}
                filter={filter}
                card={card}
                handleClick={handleClick}
              />
            )
          }

          return <div key={index}></div>
        })}
        <Grid item xs={12} className={classes.viewMore}>
          <Box display="flex" alignItems="end" justifyContent="end">
            <Button
              disableRipple
              variant="text"
              color="primary"
              onClick={() => handleShowAll()}
            >
              {showAll ? 'OCULTAR STATUS' : 'VISUALIZAR TODOS OS STATUS'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

DataProcessStatusCards.propTypes = {
  handleClick: PropTypes.func,
}

DataProcessStatusCards.defaultProps = {
  handleClick: () => {},
}

export default DataProcessStatusCards
