import { makeStyles } from '@material-ui/core'

import theme from 'theme'

const dataProcessColors = theme.colors.dataProcess.backgroundStatuses

const styles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    width: 'auto',
    height: '68px',
    display: 'flex',
  },
  departmentCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.azure,
  },
  departmentBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  departmentTitle: {
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  departmentValue: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  flagCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.azure,
  },
  flagBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  flagTitle: {
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  flagValue: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  statusCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  titleBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleCard: {
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  valueCard: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  inactive: {
    backgroundColor: dataProcessColors.inactive,
  },
  pending: {
    backgroundColor: dataProcessColors.pending,
  },
  waitingReview: {
    backgroundColor: dataProcessColors.waitingReview,
  },
  reviewDisapproved: {
    backgroundColor: dataProcessColors.reproved,
  },
  reviewApproved: {
    backgroundColor: dataProcessColors.approved,
  },
  reviewing: {
    backgroundColor: dataProcessColors.reviewing,
  },
  autoReview: {
    backgroundColor: dataProcessColors.autoReview,
  },
  pendingLia: {
    backgroundColor: dataProcessColors.pendingLia,
  },
  reviewLia: {
    backgroundColor: dataProcessColors.reviewLia,
  },
  disapprovedLia: {
    backgroundColor: dataProcessColors.disapprovedLia,
  },
}))

export default styles
